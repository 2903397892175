// 3rd party imports
import * as React from 'react';
// ui
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkList, UitkListItem } from 'uitk-react-list';
import { UitkIcon } from 'uitk-react-icons';
function Benefits() {
  return React.createElement(UitkLayoutFlex, {
    className: "Benefits container",
    space: "twelve"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkFigure, {
    roundcorner: true
  }, React.createElement(UitkImage, {
    alt: "Affiliate hub benefits",
    src: "/images/home/pose.jpg"
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "six"
  }, React.createElement(UitkHeading, {
    tag: "h2",
    size: 3
  }, React.createElement(LocalizedText, {
    message: "home.benefits.heading.why_join"
  })), React.createElement(UitkText, null, React.createElement(LocalizedText, {
    message: "home.benefits.content.our_network_provides"
  })), React.createElement(UitkList, {
    className: "Benefits__list",
    bullet: "icon-alternate",
    spacing: false
  }, React.createElement(UitkListItem, {
    key: "listitem-1-1"
  }, React.createElement(UitkIcon, {
    name: "monetization_on",
    theme: "default"
  }), React.createElement("div", null, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'two'
    }
  }, React.createElement(UitkHeading, {
    tag: "h3",
    size: 5
  }, React.createElement(LocalizedText, {
    message: "home.benefits.subheading.broadest_offerings"
  }))), React.createElement(UitkText, null, React.createElement(LocalizedText, {
    message: "home.benefits.content.inventory_unlock"
  }), React.createElement(UitkList, null, React.createElement(UitkListItem, null, React.createElement(LocalizedText, {
    message: "home.benefits.listitem.properties"
  })), React.createElement(UitkListItem, null, React.createElement(LocalizedText, {
    message: "home.benefits.listitem.activities"
  })), React.createElement(UitkListItem, null, React.createElement(LocalizedText, {
    message: "home.benefits.listitem.500airlines"
  })), React.createElement(UitkListItem, null, React.createElement(LocalizedText, {
    message: "home.benefits.listitem.rentals"
  })), React.createElement(UitkListItem, null, React.createElement(LocalizedText, {
    message: "home.benefits.listitem.cruise"
  })))))), React.createElement(UitkListItem, {
    key: "listitem-1-2"
  }, React.createElement(UitkIcon, {
    name: "apps"
  }), React.createElement("div", null, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'two'
    }
  }, React.createElement(UitkHeading, {
    tag: "h3",
    size: 5
  }, React.createElement(LocalizedText, {
    message: "home.benefits.subheading.monetize_traffic"
  }))), React.createElement(UitkText, null, React.createElement(LocalizedText, {
    message: "home.benefits.content.earn_commission"
  })))), React.createElement(UitkListItem, {
    key: "listitem-1-3"
  }, React.createElement(UitkIcon, {
    name: "shopping_cart"
  }), React.createElement("div", null, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'two'
    }
  }, React.createElement(UitkHeading, {
    tag: "h3",
    size: 5
  }, React.createElement(LocalizedText, {
    message: "home.benefits.subheading.invest_technology"
  }))), React.createElement(UitkText, null, React.createElement(LocalizedText, {
    message: "home.benefits.content.products_expand_business"
  }))))))));
}
export { Benefits };