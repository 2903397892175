// 3rd party imports
import * as React from 'react';
// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { LocalizedText } from '@shared-ui/localization-context';
// ui
import { UitkLink } from 'uitk-react-link';
import { UitkList, UitkListItem } from 'uitk-react-list';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { homeJoinNowSelectedEvent } from 'shared/utils';
var Flows = function () {
  var track = useEgClickstream();
  return React.createElement("div", {
    className: "Flows container"
  }, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'twelve'
    }
  }, React.createElement("div", null, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'four'
    }
  }, React.createElement(UitkHeading, {
    tag: "h2",
    size: 3
  }, React.createElement(LocalizedText, {
    message: "home.flows.heading.how_our_program_works"
  }))), React.createElement(UitkText, null, React.createElement(LocalizedText, {
    message: "home.flows.content.use_tools_drive_traffic"
  })))), React.createElement(UitkList, {
    type: "ordered",
    orientation: "inline",
    className: "Flows__list"
  }, React.createElement(UitkListItem, null, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'two'
    }
  }, React.createElement(UitkHeading, {
    tag: "h3",
    size: 4
  }, React.createElement(LocalizedText, {
    message: "home.flows.subheading.join"
  }))), React.createElement(UitkText, {
    size: 300
  }, React.createElement(LocalizedText, {
    message: "home.flows.content.apply_to_join"
  })), React.createElement(UitkSpacing, {
    padding: {
      blockstart: 'two'
    }
  }, React.createElement(UitkLink, {
    onClickCapture: function () {
      return homeJoinNowSelectedEvent(track, 'link');
    }
  }, React.createElement("a", {
    href: '/signup'
  }, React.createElement(LocalizedText, {
    message: "common.join_now"
  }))))), React.createElement(UitkListItem, null, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'two'
    }
  }, React.createElement(UitkHeading, {
    tag: "h3",
    size: 4
  }, React.createElement(LocalizedText, {
    message: "home.flows.subheading.share"
  }))), React.createElement(UitkText, {
    size: 300
  }, React.createElement(LocalizedText, {
    message: "home.flows.content.use_our_tools"
  }))), React.createElement(UitkListItem, null, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'two'
    }
  }, React.createElement(UitkHeading, {
    tag: "h3",
    size: 4
  }, React.createElement(LocalizedText, {
    message: "home.flows.subheading.earn"
  }))), React.createElement(UitkText, {
    size: 300
  }, React.createElement(LocalizedText, {
    message: "home.flows.content.earning"
  })))));
};
export { Flows };