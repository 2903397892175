// 3rd party imports
import * as React from 'react';
// ui
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkFigure } from 'uitk-react-images';
import { UitkButtonSize } from 'uitk-react-button';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
// components
import { JoinNowAction } from 'shared/components/layout/join-now-action';
var Hero = function () {
  return React.createElement(UitkFigure, {
    src: "/images/home/hero.jpg",
    className: "Hero"
  }, React.createElement(UitkSpacing, {
    padding: "six"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    className: "Hero__contents container",
    justifyContent: "center",
    space: "four",
    blockSize: "full_size"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkHeading, {
    tag: "h1",
    size: 2
  }, React.createElement(LocalizedText, {
    message: "home.hero.heading.join_today"
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    size: 400
  }, React.createElement(LocalizedText, {
    message: "home.hero.content.earning_commision"
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutFlex, {
    direction: "row",
    space: "four"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(JoinNowAction, {
    size: UitkButtonSize.LARGE,
    actionLocation: "hero"
  })))))));
};
export { Hero };